var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function not(fn) {
  return function () {
    return !fn.apply(this || _global, arguments);
  };
};

export default exports;